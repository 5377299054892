<template>
  <div class="first">
    <br>
    <ATitle text="第一届回顾" icon="6"></ATitle>
    <br>
    <div class="first-info center tc">
      <div class="prv">
        <div class="fl">
          <video poster="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E8%A7%86%E9%A2%91/2018/videoPoster.jpg" controls="controls" src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E8%A7%86%E9%A2%91/2018/%E5%AE%A3%E4%BC%A0%E8%A7%86%E9%A2%91.mp4"></video>
        </div>
        <div class="flex info">
          <div class="item"><h1>131家</h1><p>国内外参展企业</p></div>
          <div class="item"><h1>1600余名</h1><p>国内外参会嘉宾</p></div>
          <div class="item"><h1>3.6万人次</h1><p>参观博览会</p></div>
          <div class="item"><h1>500余项</h1><p>新产品、新技术展示</p></div>
          <div class="item"><h1>16个</h1><p>重点项目签约</p></div>
          <div class="item"><h1>200亿元</h1><p>总投资</p></div>
        </div>
      </div>
    </div>
    <CTitle text="主旨论坛"></CTitle>
    <div class="f-swiper"><router-link tag="a" to="/first-forum?thread=0"><Swiper baseUrl="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/主旨论坛/论坛照片/" :list="mainImgList"></Swiper></router-link></div>
    <br>
    <CTitle text="六大论坛"></CTitle>
    <div class="forum-outer">
      <div class="flex forum-wrap center">
        <router-link tag="a" to="/first-forum?thread=1" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/培育综合交通产业论坛/论坛封面.jpg?x-oss-process=image/resize,h_340">
          </div>
          <div class="text" style="line-height: 20px;padding-top: 10px;">培育综合交通产业 • 打造<br>交通强国示范区论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=2" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/智慧交通与未来移动出行论坛/论坛封面.jpg?x-oss-process=image/resize,h_340">
          </div>
          <div class="text">智慧交通与未来移动出行论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=3" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2050我心目中的交通强国论坛/论坛封面.jpg?x-oss-process=image/resize,h_340">
          </div>
          <div class="text">2050我心目中的交通强国论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=4" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/长三角区域交通一体化论坛/论坛封面.jpg?x-oss-process=image/resize,h_340">
          </div>
          <div class="text">长三角区域交通一体化论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=5" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022亚运交通科技论坛/论坛封面.jpg?x-oss-process=image/resize,h_340">
          </div>
          <div class="text">2022亚运交通科技论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=6" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/未来交通创新发展论坛/论坛封面.jpg?x-oss-process=image/resize,h_340">
          </div>
          <div class="text">未来交通创新发展论坛</div>
        </router-link>
      </div>
    </div>
    <CTitle text="博览会照片"></CTitle>
    <div class="elevator-wrap center">
      <div class="fl elevator">
        <div class="flex item" @click="cur=0" :class="cur==0&&'cur'">轨道交通<br>展区</div>
        <div class="flex item" @click="cur=1" :class="cur==1&&'cur'">快递及<br>现代物流<br>展区</div>
        <div class="flex item" @click="cur=2" :class="cur==2&&'cur'">智能装备<br>展区</div>
        <div class="flex item" @click="cur=3" :class="cur==3&&'cur'">航空<br>展区</div>
        <div class="flex item" @click="cur=4" :class="cur==4&&'cur'">智慧交通<br>展区</div>
      </div>
      <div class="sponsor-list">
        <SwiperNode :cur="cur" :imgList="imgList"></SwiperNode>
      </div>
    </div>
    <div class="annual-elevator">
      <router-link tag="div" class="annual" to="/fifth">第五届</router-link>
      <router-link tag="div" class="annual" to="/fourth">第四届</router-link>
      <router-link tag="div" class="annual" to="/third">第三届</router-link>
      <router-link tag="div" class="annual" to="/second">第二届</router-link>
      <div class="annual cur">第一届</div>
    </div>
  </div>
</template>
<script>
import Swiper from '@/components/Swiper'
import SwiperNode from '@/components/SwiperNode'
export default {
  components: {
    Swiper,
    SwiperNode
  },
  data() {
    return {
      cur: 0,
      mainImgList: ['18637448.jpg','18641963.jpg','18642563.jpg','18644779.jpg','18645311.jpg','18646758.jpg','18649286.jpg','18649452.jpg','18649602.jpg','18650439.jpg','18652291.jpg','18656225.jpg'],
      imgList: [
        {
          name: '轨道交通展区',
          desc: '重点展示地铁、轻轨、单轨、有轨电车、磁悬浮、市域铁路、城际铁路、重载铁路、高速铁路等相关系统的新技术、新设备、新理念',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/博览会照片/轨道交通展区/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area1.png',
          img: [['18636932.jpg','18636979.jpg','18637043.jpg','18637873.jpg','18651242.jpg','18651442.jpg','18651519.jpg','18652607.jpg','18653069.jpg'],['18680561.jpg','18746095.jpg','18746103.jpg','18751355.jpg','18752286.jpg','18759695.jpg','18817027.jpg','18637873.jpg','18751506.jpg']],
        },{
          name: '快递及现代物流展区',
          desc: '重点展示物流综合体、物流产业园建设发展成果和规划、物联网、智能仓储、自动化分拣、航空物流、港口物流、新能源物流车及配套等',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/博览会照片/快递及现代物流展区/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area2.png',
          img: [['18637141.jpg','18637416.jpg','18637580.jpg','18637647.jpg','18647719.jpg','18647948.jpg','18650257.jpg','18650624.jpg','18653163.jpg'],['18653226.jpg','18680902.jpg','18681274.jpg','18749397.jpg','18749845.jpg','18751031.jpg','18650759.jpg','18637141.jpg','18749233.jpg']],
        },{
          name: '智能装备展区',
          desc: '重点展示交通产业智能制造设备、港航设备、工程机械等',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/博览会照片/智能装备展区/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area3.png',
          img: [['18637294.jpg','18637897.jpg','18638030.jpg','18638184.jpg','18638210.jpg','18638266.jpg','18638326.jpg','18640120.jpg','18641024.jpg'],['18641038.jpg','18642706.jpg','18647286.jpg','18647711.jpg','18651413.jpg','18748270.jpg','18759945.jpg','18637997.jpg','18745827.jpg']],
        },{
          name: '航空展区',
          desc: '重点展示通航飞机、无人机、卫星导航应用及遥感、北斗终端与芯片、GPS设备、电子地图等航空产业相关新产品和新业态',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/博览会照片/航空展区/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area4.png',
          img: [['18637450.jpg','18637534.jpg','18637547.jpg','18637557.jpg','18637586.jpg','18637641.jpg','18637662.jpg','18637684.jpg','18637694.jpg'],['18637807.jpg','18638406.jpg','18638449.jpg','18642499.jpg','18642601.jpg','18679558.jpg','18683803.jpg','18637697.jpg','18638663.jpg']],
        },{
          name: '智慧交通展区',
          desc: '重点展示智慧交通系统智能化建设最新技术成果、城市大交通建设成果和规划、公共交通及交通管理设备技术和解决方案、新能源汽车等',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/博览会照片/智慧交通展区/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area8.png',
          img: [['18637082.jpg','18637158.jpg','18637211.jpg','18637227.jpg','18637217.jpg','18637271.jpg','18637281.jpg','18637303.jpg','18637330.jpg'],['18637336.jpg','18638062.jpg','18639304.jpg','18645502.jpg','18675551.jpg','18751694.jpg','18760885.jpg','18637072.jpg','18639304.jpg']]
        }
      ],
    }
  }
}
</script>

<style lang="less" scoped>
.first {
  background: url('../assets/views_bg.png') no-repeat scroll bottom / 100%;
  .first-info {
    .prv {
      margin: 20px 0;
      height: 240px;
      .fl {
        width: 423px;
        height: 238px;
        video {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          box-shadow: 0 0 20px rgba(28, 36, 52, 0.5);
        }
      }
      .info {
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-left: 50px;
        .item {
          text-align: center;
          height: 120px;
          width: 200px;
          padding: 20px 0;
          color: #125FAD;
          h1 {
            font-size: 28px;
          }
          p {
            color: #666;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .f-swiper {
    background: #F3F3F3;
    padding: 40px 0;
  }
  .forum-outer {
    background: url('../assets/maze_bg.svg') repeat;
    padding: 20px 0;
  }
  .forum-wrap {
    justify-content: space-around;
    flex-wrap: wrap;
    .forum-item {
      display: block;
      cursor: pointer;
      width: 310px;
      height: 250px;
      border-radius: 4px;
      margin-bottom: 30px;
    }
    .img {
      width: 310px;
      height: 170px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      overflow: hidden;
      img {
        max-height: 170px;
        width: auto;
        transition: all ease-out 0.2s;
      }
    }
    .forum-item:hover {
      img {
        transform: scale(1.05);
      }
    }
    .text {
      height: 60px;
      color: #333;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      border-top: 1px solid #F0F0F0;
      line-height: 60px;
      background: #F0F0F0;
    }
  }
  .elevator-wrap {
    .elevator {
      border-radius: 4px;
      width: 90px;
      height: 400px;
      left: 10px;
      border: 1px solid #D6D6D6;
      background: #FFF;
      div {
        margin: 0 auto;
      }
      .item {
        cursor: pointer;
        height: 80px;
        width: 90px;
        border-bottom: 1px solid #D6D6D6;
        color: #666666;
        font-size: 16px;
        font-weight: 400px;
        text-align: center;
      }
      .item:last-child {
        border-bottom: none;
      }
      .cur {
        background: #2585E5;
        color: #FFF;
      }
    }
  }
  .sponsor-list {
    padding-left: 110px;
  }
}
.annual-elevator {
  position: fixed;
  right: calc(50% - 640px);
  top: 20%;
  border: 1px solid #D6D6D6;
  background: #FFF;
  .annual {
    cursor: pointer;
    height: 50px;
    width: 80px;
    border-bottom: 1px solid #D6D6D6;
    color: #666666;
    font-size: 16px;
    line-height: 50px;
    font-weight: 400px;
    text-align: center;
  }
  .annual:last-child {
    border-bottom: none;
  }
  .cur {
    background: #2585E5;
    color: #FFF;
  }
}
</style>
